<template>
  <div>
    <div class="form-box">
      <div class="form-box-item">
        <div class="label">订单状态</div>
        <el-select v-model="searchStatus" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="form-box-item">
        <div class="label">订单状态</div>
        <el-date-picker v-model="searchDate" type="date" placeholder="选择日期">
        </el-date-picker>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "onlineTradingAfterSalesf",
  data() {
    return {
      searchStatus: "",
      searchDate: "",
    };
  },
};
</script>
<style lang="scss" scoped>
.form-box {
  display: flex;
  align-items: center;
  gap: 30px;
  &-item{
    display: flex;
    align-items: center;
    gap: 30px;
  }
}
</style>